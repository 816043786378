/** @jsx jsx */
import { Grid, Flex, jsx, useColorMode, Link as TLink } from "theme-ui"
import { Link } from "gatsby"
import Navigation from "./navigation"
import SocialLinks from "./social-links"
// import Hero from "./hero"

type HeaderProps = {
  meta: {
    [key: string]: string
  }
  nav: {
    title: string
    slug: string
  }[]
}

const Header = ({ meta, nav }: HeaderProps) => {
  const [colorMode, setColorMode] = useColorMode()
  const isDark = colorMode === `dark`
  const toggleColorMode = (e: any) => {
    setColorMode(isDark ? `light` : `dark`)
  }

  const navEmpty = nav.length === 0

  return (
    // <Hero image='/banner.png' slim color='#ddd'>
    <Grid
    // sx={{
      width={[ `50%`, `50%` ]}
    // }}
    >
    <Flex as="header" variant="layout.header">
      {!navEmpty && <Navigation nav={nav} />}
      <div
        sx={{
          a: {
            fontSize: 4,
            color: `text`,
            display: `flex`,
            alignItems: `center`,
            "&:hover": {
              color: `primary`,
            },
            "&:not(:first-of-type)": {
              ml: 2,
            },
          },
          justifyContent: `flex-end`,
          flex: 1,
          display: `flex`,
          order: 3,
        }}
      >
        <SocialLinks />
      </div>
    </Flex>
    <Flex
        sx={{
          fontWeight: `bold`,
          fontSize: 6,
          // flex: navEmpty ? 1 : [`1 0 100%`, 1],
          justifyContent: navEmpty ? `flex-start` : `center`,
          alignItems: `center`,
          paddingRight: `15px`, // To make the logo visually center aligned ("cutting off" the "domino" part of the logo)
          // order: [1, 2],
        }}
      >
        <TLink
          aria-label={`${meta.siteTitle}, Back to homepage`}
          as={Link}
          sx={{ paddingBottom: `2%`, px: `5%`, color: `text`, fontVariant: `small-caps`, ":hover": { color: `primary`, textDecoration: `none` } }}
          to="/"
        >
          {/* { `${meta.siteTitle}` } */}
          {<img src={`${meta.siteImage}`} width={`100% !important`} height={`100%`} alt={`${meta.siteTitle}`}/>}
        </TLink>
      </Flex>
    </Grid>
    // </Hero>
  )
}

export default Header
