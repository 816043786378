/** @jsx jsx */
import { jsx, Link as TLink, Flex } from "theme-ui"
import { Link } from "gatsby"

type NavigationProps = {
  nav: {
    title: string
    slug: string
  }[]
}

// Alternative home icon
//<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" stroke-width="2" /* stroke-linecap="round" stroke-linejoin="round" */ class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>

const Navigation = ({ nav }: NavigationProps) => (
  <Flex
    as="nav"
    sx={{
      flex: 1,
      // flexDirection: `column`,
      justifyContent: `flex-start`,
      order: [2, 1],
      "a:not(:last-of-type)": {
        mr: 3,
      },
      // background: `#2d2d2d`,
    }}
    aria-label="Primary Navigation"
  >
    <TLink
        as={Link}
        sx={{ color: `text`, paddingTop: `1px`, ":hover": { color: `primary`, textDecoration: `none` } }}
        key="/"
        to="/"
      >
        <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
<path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"/>
</svg>
      </TLink>
      <TLink
        as={Link}
        sx={{ color: `text`, paddingTop: `1px`, ":hover": { color: `primary`, textDecoration: `none` } }}
        key="/about"
        to="/about"
      >
        About
      </TLink>
      {/* <TLink
        as={Link}
        sx={{ color: `text`, paddingTop: `1px`, ":hover": { color: `primary`, textDecoration: `none` } }}
        key="/contact"
        to="/contact"
      >
        Contact
      </TLink> */}
   {/*  {nav.map((n) => (
      <TLink
        as={Link}
        sx={{ color: `text`, ":hover": { color: `primary`, textDecoration: `none` } }}
        key={n.slug}
        to={n.slug}
      >
        {n.title}
      </TLink>
    ))} */}
  </Flex>
)

export default Navigation
